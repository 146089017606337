import "./App.css";
import Label from "./components/Label_cod_orizontal";
import React, { useEffect, useState } from "react";

const columns = 3;
const rows = 7;

function App() {
  const [wineData, setWineData] = useState({
    producator: "Producator",
    model: "Model",
    tip: "Vin Alb",
    marime_sticla: "750",
    alcool: "12",
    tara_origine: "Franta",
    cod_de_bare: "",
    eco: false,
  });
  const [mesaj, setMesaj] = useState("");
  document.title = `Eticheta ${wineData?.producator || ""} ${wineData?.model || ""}`;
  return (
    <>
      <div className="control-group" style={{ padding: "20px", border: "1px solid #ddd", borderRadius: "8px", margin: "auto", width: "60vw", backgroundColor: "#f9f9f9" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div>
            <h3 style={{ textAlign: "center", marginBottom: "5px" }}>Introdu datele pentru etichetă:</h3>
            <p style={{ textAlign: "center", marginBottom: "15px" }}>Versiune 1.0.2</p>

            {Object.keys(wineData).map((key) => (
              <div key={key} style={{ marginBottom: "10px", display: "flex", flexDirection: "column" }}>
                <label style={{ fontWeight: "bold", marginBottom: "5px" }}>{key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " ")}</label>
                <input
                  type={key === "eco" ? "checkbox" : "text"}
                  checked={key === "eco" ? wineData[key] : undefined}
                  value={key !== "eco" ? wineData[key] : undefined}
                  onChange={(e) =>
                    setWineData({
                      ...wineData,
                      [key]: key === "eco" ? e.target.checked : e.target.value,
                    })
                  }
                  style={{
                    padding: key === "eco" ? "5px" : "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    outline: "none",
                    // width: "100%",
                  }}
                />
              </div>
            ))}
            <p style={{ color: "red", textAlign: "center" }}>{mesaj}</p>

            <button
              onClick={window.print}
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "16px",
                marginTop: "10px",
              }}
            >
              Descarca pdf
            </button>
            <h5
              style={{
                color: "red",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Functioneaza doar in chrome
            </h5>
          </div>
          <img
            src="instructiuni.png"
            style={{
              width: "50%",
            }}
            alt=""
          />
        </div>
      </div>
      {wineData && <Page printList={Array(columns * rows).fill(wineData)} setMesaj={setMesaj}/>}
    </>
  );
}

function Page({ printList, setMesaj }) {
  const paddingX = 7.279;
  const paddingY = 15.055;
  return (
    <div
      className="page"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        height: `${297}mm`,
        width: "210mm",
        paddingLeft: `${paddingX}mm`,
        paddingRight: `${paddingX}mm`,
        paddingTop: `${paddingY}mm`,
        paddingBottom: `${paddingY}mm`,
        margin: "0",
      }}
    >
      {printList.map((wine, index) => {
        return (
          <div
            key={index}
            style={{
              position: "relative",
            }}
          >
            <Label wine={wine} setMesaj={setMesaj}/>
          </div>
        );
      })}
    </div>
  );
}

export default App;
