import React from "react";
import Barcode from "react-barcode";
import WFLogo from "./../assets/wf-logo.svg";
import AmbalajGarantieLogo from "./../assets/sgr-logo.png";
import "./../App.css";

function isValidEAN13(ean, setMesaj = () => {}) {
  // Ensure input is exactly 13 digits
  if (!/^\d{13}$/.test(ean)) {
    setMesaj("ai introdus " + ean.length +" caractere; trebuie 13");
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(ean[i]) * (i % 2 === 0 ? 1 : 3);
  }
  let checksum = (10 - (sum % 10)) % 10;

  if(checksum === parseInt(ean[12])){
    setMesaj  ("codul de bare este valid");
    return true;
  }
  else{
    setMesaj  ("codul de bare nu este valid");
    return false;
  }
}

export default function Label(props) {
  const { wine } = props;
  const { producator, model, tip, marime_sticla, alcool, tara_origine, year } = wine;
  // const width = 210 / 3; // 70
  // const height = 297 / 8; // 37.125
  const width = 63.7;
  const height = 38.3;
  const padding = 1;
  // const padding = 4;
  const leftBannerWidth = 2.35;
  // const largeFontSize = 2.2;
  const largeFontSize = 2.4;
  const useBarcode = isValidEAN13(wine?.cod_de_bare);
  return (
    <div
      className="label"
      style={{
        display: "flex",
        position: "absolute",
        top: "0",

        // border: "1px solid #000",

        left: "0",
        width: `${width}mm`,
        height: `${height}mm`,
        paddingLeft: `${padding + 1.5}mm`,
        paddingRight: `${padding + 1.5}mm`,
        paddingBottom: `${padding}mm`,
      }}
    >
      <div
        style={{
          paddingTop: `${padding + 1}mm`,
        }}
      >
        <div
          style={{
            width: useBarcode ? "113%" : "100%",
            position: "relative",
            zIndex: "100",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "1mm",
            height: "100%",
          }}
        >
          <p
            style={{
              fontSize: `${largeFontSize}mm`,
              fontWeight: "bold",
            }}
          >
            {producator}
          </p>
          <p
            style={{
              fontSize: "2mm",
              fontWeight: "bold",
            }}
          >
            {model}
          </p>
          <p
            style={{
              fontSize: `2mm`,
            }}
          >
            {tip}
          </p>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div
              style={{
                width: !useBarcode ? "60%" : "100%",
                margin: "auto",
              }}
            >
              <p>Distribuitor: Wine Focus Intertrade SRL, Linia de Centura nr 2, Stefanestii de Jos, Ilfov 077175</p>
              <p>Tel.: 0722 345 347</p>
              <p>e-mail: contact@winefocus.ro</p>
              {wine.eco && <h5>Distributie certificata ecologic, RO-ECO-026</h5>}
            </div>
            {!useBarcode && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  paddingBottom: "1mm",
                  width: "40%",
                }}
              >
                <img src={WFLogo} width="25mm" height="25mm" alt="" />
                <img src={AmbalajGarantieLogo} width="25mm" alt="" />
              </div>
            )}
          </div>
          <div
            className="left-banner"
            style={{
              display: "flex",
              backgroundColor: "#f9a70dff",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: `${2}mm`,
              }}
            >
              www.winefocus.ro
            </p>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
              margin: "0",
            }}
          >
            <p
              style={{
                fontSize: `${largeFontSize}mm`,
              }}
            >
              {marime_sticla} ML
            </p>
            <p
              style={{
                fontSize: `${largeFontSize}mm`,
              }}
            >
              {alcool && <span> {alcool}</span>} % alc. vol
            </p>
          </div>
          <p style={{ marginTop: "auto" }}>Produs si imbuteliat in {tara_origine}</p>
          <p>{year}</p>
          <p
          // style={{
          //   marginBottom: "2mm",
          // }}
          >
            Conține sulfiți
          </p>
        </div>
      </div>
      {isValidEAN13(wine?.cod_de_bare, props.setMesaj) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              paddingBottom: "1mm",
            }}
          >
            <img src={WFLogo} width="25mm" height="25mm" alt="" />
            <img src={AmbalajGarantieLogo} width="25mm" alt="" />
          </div>
          <Barcode value={wine.cod_de_bare} format="EAN13" width={1.3} height={85} margin={0} padding={0} />
        </div>
      )}
    </div>
  );
}
